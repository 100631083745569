<template>
  <v-content color="primary">
    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ t(text) }}
      <v-btn flat @click="snackbar = false">
        {{ t('Close') }}
      </v-btn>
    </v-snackbar>
    <v-container fluid id="main-container" grid-list-md>
      <v-layout row wrap justify-center class="d-inline-block w-100 mb-5">
        <v-card>
          <v-card-title>
            <span class="menu-icon bigger material-symbols-outlined">
              calendar_clock
            </span>
            <span class="table-title">{{ t('Payment periods') }}</span>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="search" :label="t('Search')" single-line
              hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="headers" :items="paymentPeriods" :rows-per-page-text="t('Rows per page')"
            class="elevation-1" :loading="loading" :search="search" :pagination.sync="pagination" hide-actions
            :rows-per-page-items="[page_size]" :total-items="pagination.totalItems">
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">{{ props.item.name }}</td>
              <td class="text-xs-left">{{ props.item.kind_period }}</td>
              <td class="text-xs-left">{{ props.item.quantity }}</td>
              <td class="text-xs-left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="info" ripple icon small dark @click="edit(props.item)">
                      <v-icon small>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ t('Detail') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="error" ripple icon small dark @click="showRemovalWarning(props.item.id)">
                      <v-icon small>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ t('Delete') }}</span>
                </v-tooltip>
              </td>
            </template>
            <template slot="pageText" slot-scope="item">
              {{ t('total') }}: {{ item.itemsLength }}
            </template>
            <template slot="no-data">
              <v-subheader>{{ t('No data available') }}</v-subheader>
            </template>
          </v-data-table>
          <div
            style="display: flex; flex-direction: row; justify-content: center; align-items: center; flex-wrap: wrap;">
            <v-pagination v-model="pagination.current" :length="pagination.total" :total-visible="5"></v-pagination>
            <v-select style="margin-left: 10px; max-width: 50px" :items="[10, 25, 50]" v-model="page_size">
            </v-select>
          </div>
        </v-card>

      </v-layout>
      <v-dialog v-model="dialog" max-width="600px">
        <template v-slot:activator="{ on }">
          <v-fab-transition v-if="$store.getters.isAdmin || $store.getters.isOwner">
            <v-btn color="primary" dark fixed bottom right icon large v-on="on">
              <v-icon>add</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>

        <v-card>
          <v-card-title>
            <span class="headline">{{ t('Payment period') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-form ref="form">
                <v-layout wrap>
                  <v-flex xs12 sm12>
                    <v-text-field :label="t('Payment Period Name') + '*'" v-model="name" :rules="[rules.required]">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-select :items="availablePeriods" :label="t('Payment Period')" solo :rules="[rules.required]"
                      v-model="choosenAvailablePeriod">
                      <template slot='selection' slot-scope='{ item }'>
                        {{ t(item.text) }}
                      </template>
                      <template slot='item' slot-scope='{ item }'>
                        {{ t(item.text) }}
                      </template>
                    </v-select>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-text-field :label="t('Quantity (of payment period)')" v-model="numberOfPeriod" type="number"
                      :rules="[rules.counter, rules.number]">
                    </v-text-field>
                  </v-flex>

                </v-layout>
              </v-form>
            </v-container>
            <small>{{ t('*indicates required field') }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer>
            </v-spacer>
            <v-btn color="primary" text @click="closePaymentPeriodForm">{{ t('Close') }}</v-btn>
            <v-btn color="primary" text @click="submitPaymentPeriodForm">{{ t('Save') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteDialog" max-width="290">
        <v-card>
          <v-card-title class="headline">{{ t('Are you sure you want to delete?') }}
          </v-card-title>
          <v-card-text>
            {{ t('This action cannot be undone') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer>
            </v-spacer>
            <v-btn small color="error" @click="closeRemovalWarning">{{ t('Close') }}
            </v-btn>
            <v-btn outline small color="error" @click="remove">
              {{ t('Delete') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-content>
</template>

<script>
import axios from 'axios';
import {debounce} from 'vue-debounce';

export default {
  name: "PaymentPeriods",
  data() {
    return {
      pagination: {
        current: 1,
        total: 20
      },
      page_size: 10,
      next: null,
      previous: null,
      count: 0,
      headers: [
        { text: this.$translate.locale['Payment Period Name'], value: 'name' },
        { text: this.$translate.locale['Payment Period'], value: 'kind_period' },
        { text: this.$translate.locale['Quantity'], value: 'quantity' },
        { text: this.$translate.locale['Action'], value: 'action', sortable: false },
      ],
      availablePeriods: [
        { 'text': 'Days', 'value': 'DAYS' },
        { 'text': 'Weeks', 'value': 'WEEKS' },
        { 'text': 'Months', 'value': 'MONTHS' }
      ],
      choosenAvailablePeriod: "",
      name: '',
      numberOfPeriod: 1,
      paymentPeriods: [],
      choosenAvailablePeriodId: 0,
      loading: true,
      snackbar: false,
      dialog: false,
      deleteDialog: false,
      search: '',
      text: 'Oops... Something went wrong',
      timeout: 5000,
      rules: {
        required: value => !!value || this.$translate.locale['Required.'],
        counter: value => value <= 2147483647 || this.$translate.locale['Max 2147483647'],
        number: value => {
          const pattern = /^\d+$/;
          return pattern.test(value) || this.$translate.locale['Invalid number.']
        },
      },
      getDataDebounced: debounce((...args) => this.getData(...args), '300ms'),
    }
  },
  methods: {
    getData(resetPage=false) {
      let url = `/api/payment-periods/`;
      const params = {
        page: this.pagination.current,
        page_size: this.page_size,
        sort_by: this.pagination.descending ? `-${this.pagination.sortBy}` : this.pagination.sortBy,
      };

      if (this.search) {
        params.search = this.search;

        if (this.pagination.current !== 1 && resetPage) {
          this.pagination.current = 1;
          // returning because setting `this.pagination.current = 1`
          // will trigger getData() once more
          return;
        }
      }

      axios.get(url, {params})
        .then((response) => {
          if (response.status === 200) {
            this.paymentPeriods = response.data.results;
            this.next = response.data.next;
            this.previous = response.data.previous;
            this.count = response.data.count;
            this.pagination.total = response.data.total_pages;
            this.pagination.totalItems = response.data.count;
            this.loading = false;
          }
        }).catch((error) => {
          this.text = "Connection error";
          this.snackbar = true;
        });
    },
    edit(currentPayment) {
      this.dialog = true;
      this.choosenAvailablePeriodId = currentPayment.id;
      this.choosenAvailablePeriod = currentPayment.kind_period;
      this.name = currentPayment.name;
      this.numberOfPeriod = currentPayment.quantity;
    },
    showRemovalWarning(id) {
      this.deleteDialog = true;
      this.choosenAvailablePeriodId = id;
    },
    remove() {
      axios.delete(`/api/payment-periods/delete/${this.choosenAvailablePeriodId}/`)
        .then((response) => {
          if (response.status === 200) {
            this.closeRemovalWarning();
            this.submitted = true;
            this.getData();
            this.text = this.$translate.locale['Payment period deleted'];
            this.snackbar = true;
          }
        }).catch((error) => {
          this.text = this.$translate.locale["Connection error"];
          this.snackbar = true;
        });
    },
    clear() {
      this.choosenAvailablePeriodId = 0;
      this.choosenAvailablePeriod = "";
      this.name = "";
      this.numberOfPeriod = 1;
    },
    closePaymentPeriodForm() {
      this.dialog = false;
      this.clear();
    },
    closeRemovalWarning() {
      this.deleteDialog = false;
      this.clear();
    },
    submitPaymentPeriodForm() {
      if (!this.$refs.form.validate()) {
        this.text = "Fill the form correctly";
        this.snackbar = true
      } else {
        if (this.choosenAvailablePeriodId === 0) {
          this.createPaymentPeriod();
        } else {
          this.updatePaymentPeriod();
        }
      }
    },
    createPaymentPeriod() {
      const data = {
        crossdomain: true,
        name: this.name,
        kind_period: this.choosenAvailablePeriod,
        quantity: this.numberOfPeriod,
      };
      axios.post(`/api/payment-periods/create/`, data)
        .then((response) => {
          if (response.status === 201) {
            this.submitted = true;
            this.closePaymentPeriodForm();
            this.getData();
            this.text = this.$translate.locale['Payment period created'];
            this.snackbar = true;
          }
        }).catch((error) => {
          this.text = this.$translate.locale["Connection error"];
          this.snackbar = true;
        });
    },
    updatePaymentPeriod() {
      const data = {
        name: this.name,
        kind_period: this.choosenAvailablePeriod,
        quantity: this.numberOfPeriod,
      };
      axios.put(`/api/payment-periods/edit/${this.choosenAvailablePeriodId}/`, data)
        .then((response) => {
          if (response.status === 200) {
            this.submitted = true;
            this.closePaymentPeriodForm();
            this.getData();
            this.text = this.$translate.locale['Payment period updated'];
            this.snackbar = true;
          }
        }).catch((error) => {
          this.text = this.$translate.locale["Connection error"];
          this.snackbar = true;
        });
    }
  },
  beforeCreate() {
    if (!this.$store.getters.loggedIn) {
      this.$router.push('/')
    }
  },
  mounted() {
    this.getData();
  },
  watch: {
    'pagination.current': function() { this.getDataDebounced(); },
    'page_size': function(newValue, oldValue) { this.getDataDebounced(newValue !== oldValue); },
    'pagination.sortBy': function() { this.getDataDebounced(); },
    'pagination.descending': function() { this.getDataDebounced(); },
    'search': function(newValue, oldValue) { this.getDataDebounced(newValue !== oldValue); },
  }
}
</script>

<style scoped></style>
